import React from "react";
import Layout from "../components/layout";
import 'react-h5-audio-player/lib/styles.css';
import FaqComponent from "../components/faq/faq-component";
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import SEO from "../components/seo";
import {graphql} from 'gatsby';
import BreadCrumbs from "../components/bread-crumbs";
import './services.scss';

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

export default function (props) {
    const {data} = props;
    const serviceData = data.allContentfulServices.edges[0].node;
    const servicesBenefits = serviceData.benefits;
    const howitworks = serviceData.howItWorks;
    const faqs = serviceData.faqs;

    return (
        <Layout location={props.location}>
            <SEO title="Service"/>
            <BreadCrumbs currentPage={"Service"} breadCrumbsList={breadCrumbsList}/>
            <div className="service-header-wrap">
                <div className="service-head-inner">
                    <div className="service-text-wrap">
                        <h2 className="blackHeading">{serviceData.title}</h2>
                        <p className="dark-blue">{serviceData.shortDescription.shortDescription}</p>
                    </div>
                    <div className="service-anim">
                        <img className="service-img"
                             src={serviceData.coverImage.file.url}
                             alt="servicesanim"/>
                    </div>

                </div>
            </div>


            <div className="benefits-wrap">
                <div className="benefits-inner">
                    <h3 className="blackHeading2">Treatment Benefits</h3>
                    <div className="benefits-list">
                        {
                            servicesBenefits.map((bene, index) => {
                                return (
                                    <div className="single-benefit">
                                        <img className="benefits-icon"
                                             alt="Icon"
                                             src={bene.icon.file.url}/>
                                        <p className="benefits-text">{bene.desc}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>

            <div className="what-con-wrapper">
                <div className="what-con-inner">
                    <div className="disclaimer">
                        <h2 className="blackHeading2">Disclaimer</h2>
                        <p>{serviceData.disclaimer.disclaimer}</p>
                    </div>
                    <div className="how-it-works-services">
                        <div
                            className="disclaimer-Text">{documentToReactComponents(
                            JSON.parse(howitworks.raw))}</div>
                    </div>
                    <div className="reason-services">
                        <div className="reason-services-main-content">
                            <h3 className="how-num">{serviceData.reasonMainTitle}</h3>
                            <p className="how-des">{serviceData.reasonMainDesc}</p>
                        </div>
                        <div className="reason-services-reasons">
                            <h3 className="how-num">{serviceData.reason1Title}</h3>
                            <p className="how-des">{serviceData.reason1Desc}</p>
                            <h3 className="how-num">{serviceData.reason2Title}</h3>
                            <p className="how-des">{serviceData.reason2Desc}</p>
                            <h3 className="how-num">{serviceData.reason3Title}</h3>
                            <p className="how-des">{serviceData.reason3Desc}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="faq-services">
                <div className="faq-services-inner">
                    {
                        faqs.map((faqItem, index) => {
                            return (
                                <FaqComponent faqItem={faqItem} atIndex={index}
                                              key={index}/>
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    )
}
export const query = graphql` 
query getAllContentfulServices {
  allContentfulServices {
     totalCount
    edges {
      node {
        title
        shortDescription {
          shortDescription
        }
        coverImage {
          file {
            url
          }
        }
        reason1Desc
        reason1Title
        reason2Desc
        reason2Title
        reason3Desc
        reason3Title
        reasonMainDesc
        reasonMainTitle
        shortDescription {
          shortDescription
        }
        disclaimer {
          disclaimer
        }
        benefits {
          desc
          icon {
            file {
              url
            }
          }
        }
        howItWorks {
          raw
        }
        faqs {
          title {
            title
          }
          description {
            raw
          }
        
        }
      }
    }
  }
}

`;


